import React from 'react'

import Layout from 'layouts/index'
import Fade from 'react-reveal/Fade'

import TitleBox from 'components/TitleBox'
import {
  PageContainer,
  ContentWrapper,
  ContentText,
} from 'pageStyles/sobre-nos.styles'

import ultrassom from 'images/ultrassom.jpg'

const Diagnostico = () => {
  return (
    <Layout>
      <>
        <TitleBox title='Diagnóstico por Imagem' bg={ultrassom} />
        <PageContainer>
          <Fade>
            <ContentWrapper>
              <ContentText>
                <p>
                  O Setor de Diagnóstico por Imagem da Clínica Veterinária
                  Derosso conta com equipamentos modernos que produzem imagens
                  de alta qualidade e conta profissionais especializados para
                  perfeita realização de exames e laudos precisos que auxiliam
                  os veterinários a ter o melhor diagnóstico, o que é essencial
                  para o tratamento mais adequado do seu animal.
                </p>

                <ul>
                  <li>
                    <p>
                      O serviço de radiologia fornecem imagens dos ossos e
                      certos órgãos e tecidos de forma não invasiva, e com nosso
                      aparelho de Raio X Digital cuja imagens facilitam a
                      visualização e interpretação do Médico Veterinário a
                      conseguir um diagnóstico preciso e mais agilidade na
                      realização do exame
                    </p>
                  </li>
                  <li>
                    <p>
                      O exame de ultrassom produz imagens em tempo real das
                      partes internas do seu animal que não são possíveis de ser
                      percebidas no exame clínico. Nosso aparelho de ecografia é
                      de última geração produzindo imagens de alta definição que
                      além da funcionalidade de exploração, é capaz de realizar
                      biópsias, acompanhamento gestacional e coletas de
                      liquidos, como urina, trazendo grande segurança ao
                      procedimento.
                    </p>
                  </li>
                </ul>
                <p>
                  A busca pela evolução de nossos equipamentos e treinamento de
                  nossa equipe, nos diferencia em nossa região, atendendo não
                  somente casos da propria clínica, mas também de colegas que
                  nos referenciam.
                </p>
              </ContentText>
            </ContentWrapper>
          </Fade>
        </PageContainer>
      </>
    </Layout>
  )
}

export default Diagnostico
